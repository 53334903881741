<template>
    <div class="fixed top-16 bottom-0 right-0 bg-dp-1 border-r w-full p-6 pt-0 px-container sm:px-6 sm:w-[420px] max-w-full flex flex-col overflow-auto z-30 md:animate-slide">  
        <header class="flex items-center gap-4 border-b py-6 font-semibold bg-dp-1 sticky top-0">
            <span class="text-xl">Диалоги</span>
            <svg-icon class="ml-auto" name="close" width="24" height="24" @click="emitter.emit('chat:close')" />
        </header>
        <div class="flex-1 flex gap-4 overflow-auto">
            <div class="flex flex-col min-w-full chat__conversation gap-4">
                <div class="rounded-lg bg-white p-4 flex flex-col gap-2" v-for="{ user, is_new, created_at, text } in dialogs" @click.prevent="() => openDialog(user)">
                    <Author :user="user"></Author>
                    <div class="flex items-center justify-between px-1 text-sm">
                        {{text ? `${text}...` : ''}} <span class="bg-red-500 text-xs px-2 py-1 rounded text-white" v-if="is_new">Новое</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .messages {
        animation: detect-scroll linear;
        animation-timeline: scroll(self);

        --bg-if-can-scroll: var(--can-scroll) 8px;
        padding-right: var(--bg-if-can-scroll, 0);
    }
</style>

<script setup lang="ts">
    import useEmitter from '~/composables/useEmitter';

    const emitter = useEmitter();
    const props = defineProps(['dialogs']);
    const openDialog = (profile) => emitter.emit('chat:open', profile);
</script>